<template>
  <div class="root_ab_us">
    <Navbar value='-1' />
    <!-- <div class="ab_us">
    </div> -->
    <div class="inner__">
      <div class="root_aboutus">
        <div class="aboutus">About Us</div>
        <p class="p__">
          YourIndianCart is an e-commerce platform that brings you the vibrant essence of India right to your doorstep. We strive to be your go-to destination for all things Indian.
	        <br />
          <br />
          <ul>
            <li>Wide range of products: fashion, jewellery, Food items, and more.</li>
            <li>Carefully selected for quality and authenticity.</li>
            <li>User-friendly website with detailed descriptions and high-resolution images.</li>
            <li>Efficient logistics for timely delivery within India and internationally.</li>
            <li>Adds Indian elegance to customers' homes.</li>
            <li>Offers exquisite jewellery.</li>
            <li>Provides traditional Indian flavors.</li>
            <li>Celebrates Indian culture through artistry and craftsmanship.</li>
          </ul>
          <div style="font-weight: bold;">Welcome to YourIndianCart, from India to your doorstep…</div>
        </p>
      </div>
      <div class="aboutus_img">
        <div class="aboutus_img_left">
          <img src="./img4.png" class="ab_img1___">
        </div>
        <div class="aboutus_img_right">
          <div class="aboutus_img_left_top">
            <img src="./img1.png" class="ab_img2___">
          </div>
          <div class="aboutus_img_left_bottom">
            <img src="./img3.png" class="ab_img3___">
          </div>
        </div>
      </div>
    </div>
    <FooterYic />
  </div>

  
</template>
<script>
import Navbar from "../navbar/Navbar.vue";
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import FooterYic from '../homepage/footer.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    FooterYic,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    Navbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  mounted() {
    this.setNavbarRoute("aboutus");
  },
  methods: {
    ...mapMutations({
      setNavbarRoute: "routesStore/SET_NAVBAR",
    }),
  },
};
</script>
<style scoped>
.root_aboutus {
  width: 55vw;
  color: black;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 6rem 10rem 6rem;
  font-size: 1.2rem;
}
.aboutus{
  font-size: 3rem;
  color: black;
  font-weight: bold;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.ab_us {
  background-color: #f3f3f3;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.inner__{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.root_ab_us {
  background-color: #fff;
  font: "Avenir", Helvetica, Arial, sans-serif !important;
}
.aboutus_img{
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 7rem;
  margin-right: 6rem;
}
.aboutus_img_left{
  width: 25vw;
  height: 40vh;
  margin-right: 1.5rem;
  object-fit: contain;
}
.aboutus_img_right{
  width: 25vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ab_img2___, .ab_img3___{
  width: 25vw;
  height: 40vh;
  border-radius: 0.5rem;
}
.ab_img2___{
  margin-bottom: 1.5rem;
}
.ab_img1___{
  width: 25vw;
  height: 84vh;
  border-radius: 0.5rem;
}
.aboutus_img_right{
  
}
ul {
  list-style-type: square
}
.p__{
  line-height: 2.5rem;
  word-wrap: break-word;
}

.lglg {
  width: 100%;
  height: 100%;
}

.smsm {
  display: none;
}


/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
  .lglg {
    display: none;

  }

  .smsm {
    display: unset;
    width: 100%;
    height: 100%;
  }

}
</style>